<template>
  <textarea
    v-auto-height
    :placeholder="placeholder"
    :value="value"
    rows="1"
    @input="$emit('input', $event.target.value), $emit('update:value', $event.target.value)"
    @keydown.enter.prevent="search($route.name)"
    required
    maxlength="1000"
  />
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const {
  mapMutations,
} = createNamespacedHelpers('searchSettings')

export default {
  props: {
    label: String,
    placeholder: String,
    value: String
  },
  emits: ['input', 'update:value'],
  methods: {
    ...mapMutations(['search']),
  },
  directives: {
    'auto-height': {
      updated (el) {
        el.style.height = 'auto'
        el.style.height = el.scrollHeight + 'px'
      },
      mounted (el) {
        el.style.height = 'auto'
        el.style.height = el.scrollHeight + 'px'
      }
    }
  }
}
</script>

<style scoped lang="scss">

textarea {
  width: 100%;
  text-align: left;
  flex: auto;
  resize: none; /* disable resize */
  overflow: hidden; /* hide scrollbar */
  padding: var(--spacing-2);
  font: var(--primary-font);
  border: 1px solid black; /* add black border */

}
.slot {
  color: initial;
}

::placeholder {
  color: var(--color-light-text);
}
</style>