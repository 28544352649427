const { HOME, TIMELINE, LINKS, ACTIVITY, SEARCH, ACCOUNT, CRAWLREQUESTS, MEDIA, NETWORK, DASHBOARD } = require('../../constants/tools')

module.exports = {
  nav: {
    [HOME]: 'Home',
    api: 'API',
    blog: 'Blog',
    docs: 'Docs',
    contact: 'Contact',
    [ACCOUNT]: 'Account',
    [CRAWLREQUESTS]: 'Crawl Requests',
    [TIMELINE]: 'Timeline',
    [LINKS]: 'Link Counter',
    [ACTIVITY]: 'Activity',
    [SEARCH]: 'Search',
    [MEDIA]: 'Media Application',
    [NETWORK]: 'Network Graph',
    [DASHBOARD]: 'Research Dashboard',
  },
  homepage: {
    heading: {
      title: 'Analyze hate and disinformation online',
      intro: 'Open Measures was designed to help facilitate activists, journalists, researchers, and other social good organizations to analyze and visualize harmful online trends such as hate, mis-, and disinformation.',
    },
  },
  sidebar: {
    title: 'Edit analysis'
  },
  modal: {
    title: 'Edit query'
  },
  toolPicker: {
    label: 'Analysis Type',
    changeTools: 'See more options',
    modalTitle: 'Choose a tool to begin your analysis',
    modalSubtitle:
      'Each tool below will run a different analysis based on your input and return downloadable results'
  },
  toolDescription: {
    [TIMELINE]:
    'Track the frequency that a term is mentioned within a selected data source. Identify emerging trends and analyze point-in-time buildup of chatter.',
    [LINKS]:
    'Generate a graph that counts links in content containing a chosen term or set of keywords.',
    [ACTIVITY]:
    'Create a visualization to understand which users are frequently using a unique term.',
    [SEARCH]:
    'View content containing a configured search term. Flip between different sources on the same search term to gauge the discourse around a topic.',
    [MEDIA]: 'Search through media data that includes photos, videos, and zip files.',
    [NETWORK]: 'Live point-and-click graph analysis tool for cross-platform research.',
    [CRAWLREQUESTS]: 'Request crawls of specific keywords on sources we collect.',
    [DASHBOARD]: 'Run data analytics at scale for in-depth investigations.',
  },
  consent: {
    contentWarning: 'Warning: Open Measures crawls data from fringe internet sites. Content may be disturbing.',
    consentReminder: 'Search is disabled until you consent to the content warning.',
    confirmConsent: 'Confirm consent'
  },
  setting: {
    term: 'Search term',
    termPlaceholder: 'Enter search term',
    advancedTermPlaceholder: 'Enter search term (advanced)',
    startDate: 'Start date',
    endDate: 'End date',
    freeDates: 'In the free version of the app you can only select dates over half a year old.',
    pickWebsite: 'Social media website to search',
    twitterVerifiedNote: 'Note: our tools only analyze tweets from verified Twitter accounts.',
    aggBy: {
      label: 'Aggregate Reddit by',
      author: 'Author',
      subreddit: 'Subreddit'
    },
    numberHashtags: 'Number of hashtags',
    numberUrls: 'Number of urls',
    interval: 'Interval',
    times: {
      hour: 'hour',
      day: 'day',
      week: 'week',
      month: 'month'
    },
    timely: {
      hour: 'Hourly',
      day: 'Daily',
      week: 'Weekly',
      month: 'Monthly'
    },
    limit: 'Limit',
    showChangepoint: 'Show changepoint',
    changepointInfo: 'A point in time where the statistical properties of the data in the time series changes significantly',
    button: {
      timeline: 'See timeline results',
      hashtags: 'See hashtag detector results',
      urls: 'See URLs graph',
      activity: 'See activity',
      search: 'See search results'
    }
  },
  chart: {
    timelineOn: 'Timeline on ',
    hashtagsWith: 'Hashtags Occurring with ',
    linkCountOn: 'Link Counter on ',
    activityOn: 'Activity on ',
    postsPer: 'Posts containing ',
    popularLinksWith: 'Popular links occurring with ',
    termAggBy: 'Aggregation field per ',
    downloadPNG: 'Save as PNG'
  },
  table: {
    date: 'Date',
    usage: 'Usage',
    urls: 'URLs',
    urlCount: 'URL count',
    user: 'User',
    count: 'Count',
    hashtags: 'Hashtags',
    hashtagFreq: 'Hashtag frequency',
    viewFor: 'Table View for ',
    view: 'Table View',
    downloadCSV: 'Download CSV'
  },
  searchResults: {
    yourSearch: "Posts from {site} containing '{term}'",
    maxResultsNote: 'Maximum of 50 comments shown.',
    seeOriginal: 'See original',
    noResults: 'Try a different search term or larger time interval.'
  },
  data: {
    beforeChangepoint: 'Before {site} changepoint',
    afterChangepoint: 'After {site} changepoint'
  }
}
