<template>
  <q-toolbar class="toolbar">
    <q-toolbar-title
      class="title"
    >
    <div class="parent">
      <router-link :to="'/'">
        <img :src='omLogo' class='om-logo' />
      </router-link>
    </div>
    </q-toolbar-title>
    <q-space />

    <q-btn
      v-if="!isTool"
      class="home-mobile-menu"
      icon="menu"
      unelevated
      dense
      @click="$emit('toggleMenu')"
    />
    <q-btn
      v-if="isTool"
      class='edit-query'
      label='Edit Query'
      unelevated
      dense
      color="purple-highlight"
      @click="$emit('toggleToolBar')"
      style='font-size: 10px; line-height: 13px; padding: 3px 10px;'
    />

    <div class="right-part">
      <div
        class="right-part-nav">
        <router-link
            class="subsection-link"
            to="/"
          >
          <ButtonInner :active="$route.name === HOME">
            <span class='top-bar-link'> Home </span>
          </ButtonInner>
        </router-link>
        <a href='https://docs.openmeasures.io/partners' target='_blank'>
          <ButtonInner>
            <span class='top-bar-link'>{{ $t("nav.docs") }}</span>
          </ButtonInner>
        </a>
      </div>
      <ProfileButton />
      <div class="right-part-lang">
        <LanguagePickerDropdown />
      </div>
    </div>
  </q-toolbar>
</template>

<script>
import ButtonInner from '@/components/input/ButtonInner'
import LanguagePickerDropdown from '@/components/input/LanguagePickerDropdown'
import ProfileButton from './ProfileButton.vue'
import omLogo from '@/assets/open-measures-logo-full.svg'
const { HOME, TIMELINE, HASHTAGS, LINKS, ACTIVITY, SEARCH } = require('@/constants/tools')

export default {
  components: {
    ButtonInner,
    ProfileButton,
    LanguagePickerDropdown,
  },
  data () {
    return {
      omLogo,
      HOME,
    }
  },
  computed: {
    isTool () {
      return [TIMELINE, HASHTAGS, LINKS, ACTIVITY, SEARCH].includes(this.$route.name)
    },
  },
}
</script>

<style scoped lang="scss">
.subsection-link {
  color: var(--color-black);
}
.parent {
  display: flex;
  align-items: center;
  background-color: var(--color-off-white);
}
.toolbar {
  background-color: var(--color-black);
  // width: 90%; /* Adjust as needed */
  // margin: 0 auto; /* Centers the toolbar */
  // border-bottom: 2px solid var(--color-black);
  .router-link-active {
    text-decoration: none !important;
  }

  .toolbar-back {
    font-size: 24px;
    font-weight: bold;
  }

  .title {
    font-weight: 800;

    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
    align-items: center;

    img.om-logo {
      height: 22px;
    };

    div.env {
      color: red;
    }
  }

  .home-mobile-menu {
    display: none;
  }

  .edit-query {
    display: none;
    margin-right: 2em;
    padding: 0 5px;
  }

  .right-part {
    display: flex;
    align-items: center;

    .right-part-nav {
      display: flex;
      align-items: center;

      > * {
        margin-right: 3rem;
      }
    }
    a {
      font-weight: bold;
      color: var(--color-purple);

      &.router-link-exact-active {
        color: gray;
      }
    }

    .api-button { cursor: pointer; }
  }

  .top-bar-link {
    font-size: 12px;
    &.active {
        border-bottom-color: var(--color-primary);
    }
  }
}

@media (max-width: 1000px) {
  .toolbar .edit-query {
    display: grid;
  }
}

@media (max-width: 860px) {
  .toolbar .top-bar-link {
    font-size: 14px;
  }
}

@media (max-width: 650px) {
  .toolbar {
    .title {
      display: grid;
    }

    .edit-query {
      margin-right: 5px;
    }

    .home-mobile-menu {
      display: grid;
    }

    .right-part {
      margin-right: 1rem;
      display: none;
    }
  }
}
</style>
