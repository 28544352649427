module.exports = {
  nav: {
    home: 'Inicio',
    api: 'API',
    blog: 'Blog',
    docs: 'Docs',
    contact: 'Schedule a Demo',
    account: 'Account',
    crawlrequests: 'Crawl Requests',
    timeline: 'Línea del tiempo',
    links: 'Contador de enlaces',
    activity: 'Actividad',
    search: 'Buscar',
    media: 'Media Application',
    network: 'Network Graph',
    dashboard: 'Research Dashboard',
  },
  homepage: {
    heading: {
      title: 'Analyze hate and disinformation online',
      intro: 'Open Measures was designed to help facilitate activists, journalists, researchers, and other social good organizations to analyze and visualize harmful online trends such as hate, mis-, and disinformation.',
    },
  },
  sidebar: {
    title: 'Edit analysis',
  },
  modal: {
    title: 'Edit query',
  },
  toolPicker: {
    label: 'Open Measures',
    changeTools: 'Change tools',
    modalTitle: 'Choose a tool to begin your analysis',
    modalSubtitle: 'Each tool below will run a different analysis based on your input and return downloadable results',
  },
  toolDescription: {
    timeline: 'The timeline functionality takes a search term and makes a graph of how often it was written over time',
    links: 'The link counter functionality takes a search term and returns a bar graph counting links in comments with that term',
    activity: 'This takes a search term and creates a bar graph of a particular field. For most sources this is the author of the content',
    search: 'View content containing a configured search term. Flip between different sources on the same search term to gauge the discourse around a topic.',
    media: 'Search through media data that includes photos, videos, and zip files.',
    network: 'Live point-and-click graph analysis tool for cross-platform research.',
    crawlrequests: 'Request crawls of specific keywords on sources we collect.',
    dashboard: 'Run data analytics at scale for in-depth investigations.',
  },
  consent: {
    contentWarning: 'Warning: Open Measures crawls data from fringe internet sites. Content may be disturbing.',
    consentReminder: 'Search is disabled until you consent to the content warning.',
    confirmConsent: 'Confirm consent',
  },
  setting: {
    term: 'Término de búsqueda',
    termPlaceholder: 'Ingrese el término de búsqueda',
    advancedTermPlaceholder: 'Enter search term (advanced)',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha final',
    freeDates: 'In the free version of the app you can only select dates over half a year old.',
    pickWebsite: 'Sitio web de redes sociales para buscar',
    twitterVerifiedNote: 'Note: our tools only analyze tweets from verified Twitter accounts.',
    aggBy: {
      label: 'Aggregate Reddit by',
      author: 'Author',
      subreddit: 'Subreddit',
    },
    numberHashtags: 'Número de hashtags',
    numberUrls: 'Número de urls',
    interval: 'Intervalo',
    times: {
      hour: 'hour',
      day: 'day',
      week: 'week',
      month: 'month',
    },
    timely: {
      hour: 'A cada hora',
      day: 'Diario',
      week: 'Semanal',
      month: 'Mensual',
    },
    limit: 'Límite',
    showChangepoint: 'Show changepoint',
    changepointInfo: 'A point in time where the statistical properties of the data in the time series changes significantly',
    button: {
      timeline: 'Ver resultados de la línea de tiempo',
      hashtags: 'Ver resultados del detector de hashtag',
      urls: 'Ver gráfico de URL',
      activity: 'Ver actividad',
      search: 'See search results',
    },
  },
  chart: {
    timelineOn: 'Timeline on ',
    hashtagsWith: 'Hashtags Occurring with ',
    linkCountOn: 'Link Counter on ',
    activityOn: 'Activity on ',
    postsPer: 'Posts containing  ',
    popularLinksWith: 'Popular links occurring with ',
    termAggBy: 'Aggregation field per ',
    downloadPNG: 'Save as PNG',
  },
  table: {
    date: 'Fecha',
    usage: 'Uso',
    urls: 'URLs',
    urlCount: 'Conteo de URLs',
    user: 'User',
    count: 'Conteo',
    hashtags: 'Hashtags',
    hashtagFreq: 'Frecuencia de hashtag',
    viewFor: 'Table View for ',
    view: 'Vista de tabla',
    downloadCSV: 'Download CSV',
  },
  searchResults: {
    yourSearch: "Posts from {site} containing '{term}'",
    maxResultsNote: 'Maximum of 50 comments shown.',
    seeOriginal: 'See original',
    noResults: 'Try a different search term or larger time interval.',
  },
  data: {
    beforeChangepoint: 'Before {site} changepoint',
    afterChangepoint: 'After {site} changepoint',
  },
}