<template>
  <div>
    <div class="banner">
      Effective September 10th, the new Pro API endpoint is the only functional Pro API endpoint. All functionality is the same as the old endpoint, you only need to change the URL if you have not made the switch already. Thanks and let us know if you have any questions via email or via Slack.
  </div>
    <div class="home-header text-title-3 text-bold">
    Crawl Requests
    </div>
    <q-tabs
        @update:modelValue="clearSearch"
        v-model="tab"
        narrow-indicator
        dense
        align="justify"
        style="margin-bottom: 20px;"
    >
    <q-tab class="text-purple" name="keywords" label="Keywords"></q-tab>
    <q-tab class="text-purple" name="profiles" label="Profiles"></q-tab>
    <q-tab class="text-purple" name="channels" label="Telegram Channels"></q-tab>
  </q-tabs>
  <div v-if="tab === 'profiles'">
    <q-table
      flat bordered
      :dense="$q.screen.lt.md"
      :rows="profiles"
      :rows-per-page-options="[15,50,100]"
      :columns="profileColumns"
      row-key="id"
      separator="cell"
      :loading="loading"
      :filter="filter"
      @request="onProfileRequest"
      v-model:pagination="initialProfilePagination"
    >
    <template v-slot:body-cell-id="props">
        <q-td :props="props" @click="onRowClick(props, 'id')">
          {{ doTruncateId(props.row.id) }}
          <q-tooltip>
            View full ID
          </q-tooltip>
        <q-dialog v-model="props.row.expandId" max-width="80vw">
        <q-card bordered>
          <q-card-section class="row items-center q-pb-none" style="margin-bottom: 5px;">
          <div class="text-h6" style="margin-right: 200px;">Full ID</div>
          <q-space></q-space>
          <q-btn icon="close" flat round dense v-close-popup></q-btn>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section style="max-height: 50vh" class="scroll">
          <div >
            {{ props.row.id }}
          </div>
        </q-card-section>
        </q-card>
      </q-dialog>
      </q-td>
      </template>
      <template v-slot:body-cell-source_url="props">
        <q-td :props="props" @click="onRowClick(props, 'url')">
          {{ doTruncate(props.row.source_url) }}
          <q-tooltip>
            View full URL
          </q-tooltip>
        <q-dialog v-model="props.row.expandUrl" max-width="80vw">
        <q-card bordered>
          <q-card-section class="row items-center q-pb-none" style="margin-bottom: 5px;">
          <div class="text-h6" style="margin-right: 200px;">Full URL</div>
          <q-space></q-space>
          <q-btn icon="close" flat round dense v-close-popup></q-btn>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section style="max-height: 50vh" class="scroll">
          <div >
            {{ props.row.source_url }}
          </div>
        </q-card-section>
        </q-card>
      </q-dialog>
    </q-td>
      </template>
    <template v-slot:body-cell-isActive="props">
      <q-td :props="props">
        <div class="icon-container">
          <q-btn flat
            :icon="hoveredRow === props.row.id ? 'delete' : 'delete'"
            :color="hoveredRow === props.row.id ? 'red' : 'black'"
            @mouseover="hoveredRow = props.row.id"
            @mouseleave="hoveredRow = null"
            @click="deleteProfile(props.row)"
          />
          <q-tooltip>
          Delete profile from monitoring
        </q-tooltip>
        </div>
      </q-td>
    </template>
    <template v-slot:header-cell-source="props">
        <q-th :props="props">
          <q-tooltip>
            Click to filter
          </q-tooltip>
          Source
          <q-btn-dropdown
            flat no-caps
            color="white"
            text-color="black"
            dropdown-icon='img:sort.svg'
            v-model="dropdown"
          >
        <div class="row no-wrap q-pa-md">
        <div class="column">
          <q-checkbox
            color="black"
            v-model="allSites"
            text-color="purple"
            label="(Select All)"
          >
        </q-checkbox>
          <hr /> <!-- Horizontal line -->
          <div class="column" v-for="(site, index) in sites" :key="index">
          <div class="flex items-center no-wrap">
            <q-checkbox
              color="black"
              v-model="site.active"
            >
            </q-checkbox>
            <WebsiteIcon :website="site.name" class="website-icon margin-right" />
            {{ site.label }}
          </div>
        </div>
        <q-btn color="black" :disable="loading" label="Filter" class="margin-top" @click="updateSiteStatus()"></q-btn>
        </div>
        </div>
        </q-btn-dropdown>
        </q-th>
      </template>
      <template v-slot:top-left>
        <q-btn color="black" :disable="loading || !capacityAvailable" label="Add profile" icon-right="add"  @click="openForm"></q-btn>
        <q-tooltip v-if="!capacityAvailable">
          Your account has a maximum number of allowed crawl requests. Please contact info@openmeasures.io to request more
        </q-tooltip>
        <q-linear-progress size="22px" :indeterminate="loading" :value="this.totalProfilesPercentage" color="accent" class="q-mt-sm">
          <q-tooltip>
          Profiles only count toward the maximum if they are active. Inactive profiles do not count toward the maximum.
          </q-tooltip>
          <div class="absolute-full flex flex-center">
          <q-badge color="white" text-color="black" :label="this.totalProfilesLabel"></q-badge>
        </div>
        </q-linear-progress>
        <q-dialog v-model="showForm">
        <q-card>
          <q-card-section style="margin-right: 200px;">
          <div class="text-h6">
            Add Profiles
          </div>
        </q-card-section>
        <q-card-section>
          Request profile crawling.
          Multiple profiles can be added by separating them with a new line.
          Make sure to choose the correct profile type and ID type.
        </q-card-section>
        <q-card-section>
          <q-form @submit="addProfile">
            <q-input autogrow outlined
            color="black"
            class="scroll q-mb-md"
            v-model="newProfile"
            label="Profile"
          />
            <!-- <q-input outlined color="black" class="scroll q-mb-md" v-model="newProfile" label="Profile"/> -->
            <q-select outlined v-model="profileOption" :options="this.profileOptions" label="Select profile type" class="scroll q-mb-md">
              <q-tooltip class="bg-purple" anchor="top middle">
                Profile type is the type of profile you are requesting. For example, if you are requesting a user profile, you would select "User" as the profile type.
              </q-tooltip>
            </q-select>
            <q-select outlined v-model="profileId" :options="this.profileIds" label="Select ID type">
              <q-tooltip class="bg-purple" anchor="top middle">
                This is the profile identifier you are requesting. For example, if you are providing links to the profiles then you will select URL. Open Measures will resolve the identifier regardless of the type for crawling.
              </q-tooltip>
            </q-select>
            <q-checkbox
            class="margin-top"
            color="black"
            v-model="allSites"
            text-color="purple"
            label="(Select All)"
          >
        </q-checkbox>
            <div class="column" v-for="(site, index) in sites" :key="index">
          <div class="flex items-center">
            <q-checkbox
              color="black"
              v-model="site.active"
            >
            </q-checkbox>
            <WebsiteIcon :website="site.name" class="website-icon margin-right" />
            {{ site.label }}
          </div>
          </div>
            <q-btn
            :disable="newProfile === '' || !capacityAvailable || numNewProfiles < 1" type="submit" class="margin-top" icon-right="add" color="black" :loading="loading"
            >
            Add {{ numNewProfiles }} profiles ({{ totalProfilesRemaining }} remaining)
              <template v-slot:loading>
                <q-spinner-grid></q-spinner-grid>
              </template>
            </q-btn>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
    </template>
    <template v-slot:top-right>
        <q-input borderless dense debounce="1000" v-model="filter" :disable="loading" placeholder="Search profiles">
          <template v-slot:append>
            <q-icon name="search"></q-icon>
          </template>
          <q-tooltip>
            Search through requested profiles by usernames only
          </q-tooltip>
        </q-input>
      </template>
      <template v-if="isAdmin" v-slot:body-cell-toggleStatus="props">
        <q-td :props="props">
          <q-toggle
              v-model="props.row.isActive"
              color="purple"
              checked-icon="check"
              unchecked-icon="clear"
              @update:model-value="updateProfile(props.row)"
            >
          <q-tooltip>
            Toggle active status
          </q-tooltip>
          </q-toggle>
        </q-td>
      </template>
      <template v-else v-slot:body-cell-toggleStatus="props">
        <q-td :props="props">
          <div class="text-center">
          <q-icon name="done_round" color="green" v-if="props.row.isActive" />
          <q-icon name="clear_round" color="red" v-else />
          </div>
        </q-td>
      </template>
      <template v-slot:header-cell-toggleStatus="props">
        <q-th :props="props">
          {{ props.col.label }}
          <q-icon name="info_outline" color="purple"></q-icon>
          <q-tooltip>
            Profiles show as active when the crawler could discover them. If the profile is not active, it may not exist or the crawler was unable to find it.
          </q-tooltip>
          </q-th>
      </template>
      <template v-slot:body-cell-source="props">
        <q-td :props="props">
            <WebsiteIcon :website="props.row.source" class="website-icon" />
            {{ props.row.source }}
        </q-td>
      </template>
    </q-table>
  </div>
  <div v-if="tab === 'channels'">
    <q-table
      flat bordered
      :rows="channels"
      :rows-per-page-options="[15,50,100]"
      :dense="$q.screen.lt.md"
      :columns="channelColumns"
      row-key="id"
      separator="cell"
      :loading="loading"
      :filter="filter"
      @request="onChannelRequest"
      v-model:pagination="initialChannelPagination"
    >
    <template v-slot:header-cell-totalCount="props">
        <q-th :props="props">
          <q-tooltip>
            Click to filter
          </q-tooltip>
          {{  `Activity (${this.timeDuration})`  }}
          <q-btn-dropdown
            flat no-caps
            color="white"
            text-color="black"
            dropdown-icon='img:sort.svg'
            v-model="countDropdown"
          >
        <div class="row no-wrap q-pa-md">
        <div class="column">
        <q-radio color="black" v-model="timeDuration" val="day" label="Last 24 hours"></q-radio>
        <q-radio color="black" v-model="timeDuration" val="week" label="Last week"></q-radio>
        <q-radio color="black" v-model="timeDuration" val="month" label="Last month"></q-radio>
        <q-radio color="black" v-model="timeDuration" val="year" label="Last year"></q-radio>
        <q-btn color="black" :disable="loading" label="Filter" class="margin-top" @click="updateTimeDurationChannels()" :loading="loading">
          <template v-slot:loading>
              <q-spinner-grid></q-spinner-grid>
          </template>
        </q-btn>
        </div>
        </div>
        </q-btn-dropdown>
        </q-th>
    </template>
    <template v-slot:body-cell-isActive="props">
      <q-td :props="props">
        <div class="icon-container">
          <q-btn flat
            :icon="hoveredRow === props.row.id ? 'delete' : 'delete'"
            :color="hoveredRow === props.row.id ? 'red' : 'black'"
            @mouseover="hoveredRow = props.row.id"
            @mouseleave="hoveredRow = null"
            @click="deleteChannel(props.row)"
          />
          <q-tooltip>
          Delete channel from monitoring
        </q-tooltip>
        </div>
      </q-td>
    </template>
    <template v-slot:body-cell-totalCount="props">
      <q-td :props="props">
        <q-spinner-grid v-if="props.row.loading" /> <!-- Display loading icon if loading is true -->
        <template v-else> <!-- Display the content if loading is false -->
          <template v-if="props.row.isActive">
            {{ props.row.totalCount }}
            <span v-if="props.row.totalCount > 0">
              ({{ Math.round(props.row.percentChange * 100)}}%)
              <q-icon name="trending_up" color="green" v-if="props.row.percentChange > 0" />
              <q-icon name="trending_down" color="red" v-else-if="props.row.percentChange < 0" />
            </span>
          </template>
          <template v-else>
            N/A
          </template>
        </template>
      </q-td>
    </template>
    <template v-slot:top-left>
        <q-btn color="black" :disable="loading || !capacityAvailable" label="Add Channels" icon-right="add"  @click="openForm"></q-btn>
        <q-tooltip v-if="!capacityAvailable">
          Your account has a maximum number of allowed crawl requests. Please contact info@openmeasures.io to request more
        </q-tooltip>
        <q-linear-progress size="22px" :indeterminate="loading" :value="this.totalChannelsPercentage" color="accent" class="q-mt-sm">
          <q-tooltip>
          Channels only count toward the maximum if they are active. Inactive channels do not count toward the maximum.
        </q-tooltip>
          <div class="absolute-full flex flex-center">
          <q-badge color="white" text-color="black" :label="this.totalChannelsLabel"></q-badge>
        </div>
        </q-linear-progress>
      <q-dialog v-model="showForm">
      <q-card>
        <q-card-section style="margin-right: 200px;">
          <div class="text-h6">
            <WebsiteIcon website="telegram" class="website-icon margin-right" />
            Add Channels
          </div>
        </q-card-section>
        <q-card-section>
          Request Telegram channel crawling.
          Multiple channels can be added by separating them with a new line.
        </q-card-section>
        <q-card-section>
          <q-form @submit="addChannelRequest">
          <q-input autogrow outlined
            color="black"
            class="scroll"
            v-model="newChannel"
            label="Channel"
            error-message="Please remove spaces from channel names"
            :error="!isValid"
          />
          <q-btn
            :disable="newChannel === '' || !inputIsValid || !capacityAvailable"
            type="submit"
            class="margin-top"
            icon-right="add"
            color="black"
            :loading="loading"
          >
            Add {{ numNewChannels }} channels ({{ totalChannelsRemaining }} remaining)
            <template v-slot:loading>
              <q-spinner-grid></q-spinner-grid>
            </template>
          </q-btn>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
      </template>
      <template v-slot:no-data="{ message }">
        <div class="full-width row flex-center text-accent q-gutter-sm">
          <span>
            {{ message }}
          </span>
        </div>
      </template>
      <template v-slot:top-right>
        <q-input borderless dense debounce="1000" v-model="filter" :disable="loading" placeholder="Search">
          <template v-slot:append>
            <q-icon name="search"></q-icon>
          </template>
          <q-tooltip>
            Search for requested channels
          </q-tooltip>
        </q-input>
      </template>
      <template v-if="isAdmin" v-slot:body-cell-toggleStatus="props">
        <q-td :props="props">
          <q-toggle
              v-model="props.row.isActive"
              color="purple"
              checked-icon="check"
              unchecked-icon="clear"
              @update:model-value="updateChannel(props.row)"
            >
            <q-tooltip>
            Turn channel crawl request on or off
            </q-tooltip>
        </q-toggle>
        </q-td>
      </template>
      <template v-else v-slot:body-cell-toggleStatus="props">
        <q-td :props="props">
          <div class="text-center">
          <q-icon name="done_round" color="green" v-if="props.row.isActive" />
          <q-icon name="clear_round" color="red" v-else />
          </div>
        </q-td>
      </template>
      <template v-slot:header-cell-toggleStatus="props">
        <q-th :props="props">
          {{ props.col.label }}
          <q-icon name="info_outline" color="purple"></q-icon>
          <q-tooltip>
            Channels show as active when the crawlers were able to discover them. If the channel is not active, it may not exist or the crawler was unable to find it.
          </q-tooltip>
          </q-th>
      </template>
      <template v-if="isAdmin" v-slot:body-cell-toggleMedia="props">
        <q-td :props="props">
          <q-toggle
              v-model="props.row.mediaActive"
              color="purple"
              checked-icon="check"
              unchecked-icon="clear"
              @update:model-value="updateChannel(props.row)"
            >
            <q-tooltip>
            Turn media crawling on or off
          </q-tooltip>
          </q-toggle>
        </q-td>
      </template>
    </q-table>
  </div>
  <div v-if="tab === 'keywords'">
    <q-table
      flat bordered
      :rows-per-page-options="[15,50,100]"
      :dense="$q.screen.lt.md"
      :rows="keywords"
      :columns="columns"
      row-key="id"
      separator="cell"
      :loading="loading"
      :filter="filter"
      @request="onKeywordRequest"
      v-model:pagination="initialPagination"
    >
    <template v-slot:body-cell-id="props">
        <q-td :props="props" @click="onRowClick(props, 'id')">
          {{ doTruncateId(props.row.id) }}
          <q-tooltip>
            View full ID
          </q-tooltip>
        <q-dialog v-model="props.row.expandId" max-width="80vw">
        <q-card bordered>
          <q-card-section class="row items-center q-pb-none" style="margin-bottom: 5px;">
          <div class="text-h6" style="margin-right: 200px;">Full ID</div>
          <q-space></q-space>
          <q-btn icon="close" flat round dense v-close-popup></q-btn>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section style="max-height: 50vh" class="scroll">
          <div >
            {{ props.row.id }}
          </div>
        </q-card-section>
        </q-card>
      </q-dialog>
      </q-td>
      </template>
    <template v-slot:no-data="{ message }">
        <div class="full-width row flex-center text-accent q-gutter-sm">
          <span>
            {{ message }}
          </span>
        </div>
      </template>
      <template v-slot:header-cell-totalCount="props">
        <q-th :props="props">
          <q-tooltip>
            Click to adjust timeframe
          </q-tooltip>
          {{ `Activity (${this.timeDuration})` }}
          <q-btn-dropdown
            flat no-caps
            color="white"
            text-color="black"
            dropdown-icon='img:sort.svg'
            v-model="countDropdown"
          >
        <div class="row no-wrap q-pa-md">
        <div class="column">
        <q-radio color="black" v-model="timeDuration" val="day" label="Last 24 hours"></q-radio>
        <q-radio color="black" v-model="timeDuration" val="week" label="Last week"></q-radio>
        <q-radio color="black" v-model="timeDuration" val="month" label="Last month"></q-radio>
        <q-radio color="black" v-model="timeDuration" val="year" label="Last year"></q-radio>
        <q-btn color="black" :disable="loading" label="Filter" class="margin-top" @click="updateTimeDuration()" :loading="loading">
          <template v-slot:loading>
              <q-spinner-grid></q-spinner-grid>
          </template>
        </q-btn>
        </div>
        </div>
        </q-btn-dropdown>
        </q-th>
      </template>
      <template v-slot:header-cell-source="props">
        <q-th :props="props">
          <q-tooltip>
            Click to filter
          </q-tooltip>
          Source
          <q-btn-dropdown
            flat no-caps
            color="white"
            text-color="black"
            dropdown-icon='img:sort.svg'
            v-model="dropdown"
          >
        <div class="row no-wrap q-pa-md">
        <div class="column">
          <q-checkbox
            color="black"
            v-model="allSites"
            text-color="purple"
            label="(Select All)"
          >
        </q-checkbox>
          <hr /> <!-- Horizontal line -->
          <div class="column" v-for="(site, index) in sites" :key="index">
          <div class="flex items-center no-wrap">
            <q-checkbox
              color="black"
              v-model="site.active"
            >
            </q-checkbox>
            <WebsiteIcon :website="site.name" class="website-icon margin-right" />
            {{ site.label }}
          </div>
        </div>
        <q-btn color="black" :disable="loading" label="Filter" class="margin-top" @click="updateSiteStatus()"></q-btn>
        </div>
        </div>
        </q-btn-dropdown>
        </q-th>
      </template>
      <template v-slot:top-left>
        <q-btn color="black" :disable="loading || !capacityAvailable" label="Add keyword" icon-right="add"  @click="openForm"></q-btn>
        <q-tooltip v-if="!capacityAvailable">
          Your account has a maximum number of allowed crawl requests. Please contact info@openmeasures.io to request more
        </q-tooltip>
        <q-linear-progress size="22px" :indeterminate="loading" :value="this.totalKeywordsPercentage" color="accent" class="q-mt-sm">
          <div class="absolute-full flex flex-center">
          <q-badge color="white" text-color="black" :label="this.totalKeywordsLabel"></q-badge>
          </div>
        </q-linear-progress>
        <q-dialog v-model="showForm">
        <q-card>
          <q-card-section style="margin-right: 200px;">
          <div class="text-h6">
            Add Keywords
          </div>
        </q-card-section>
        <q-card-section>
          Request keyword crawling.
          Multiple keywords can be added by separating them with a new line.
        </q-card-section>
        <q-card-section>
          <q-form @submit="addKeyword">
            <q-input autogrow outlined
              color="black"
              class="scroll q-mb-md"
              v-model="newKeyword"
              label="Keyword"
            />
            <!-- <q-input outlined color="black" class="scroll" v-model="newKeyword" label="Keyword" /> -->
            <q-checkbox
            class="margin-top"
            color="black"
            v-model="allSites"
            text-color="purple"
            label="(Select All)"
          >
        </q-checkbox>
            <div class="column" v-for="(site, index) in sites" :key="index">
          <div class="flex items-center">
            <q-checkbox
              color="black"
              v-model="site.active"
            >
            </q-checkbox>
            <WebsiteIcon :website="site.name" class="website-icon margin-right" />
            {{ site.label }}
          </div>
          </div>
            <q-btn
            :disable="newKeyword === '' || !capacityAvailable || numNewKeywords < 1" type="submit" class="margin-top" icon-right="add" color="black" :loading="loading"
            >
              Add {{ numNewKeywords }} keywords ({{ totalKeywordsRemaining }} remaining)
              <template v-slot:loading>
                <q-spinner-grid></q-spinner-grid>
              </template>
            </q-btn>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
    </template>
      <template v-slot:top-right>
        <q-input borderless dense debounce="1000" v-model="filter" :disable="loading" placeholder="Search">
          <template v-slot:append>
            <q-icon name="search"></q-icon>
          </template>
          <q-tooltip>
            Search for requested keywords
          </q-tooltip>
        </q-input>
      </template>
      <template v-if="isAdmin" v-slot:body-cell-toggleStatus="props">
        <q-td :props="props">
          <q-toggle
              v-model="props.row.isActive"
              color="purple"
              checked-icon="check"
              unchecked-icon="clear"
              @update:model-value="updateKeyword(props.row)"
            >
          <q-tooltip>
            Toggle active status
          </q-tooltip>
          </q-toggle>
        </q-td>
      </template>
      <template v-slot:body-cell-isActive="props">
        <q-td :props="props">
          <div class="icon-container">
            <q-btn flat
              :icon="hoveredRow === props.row.id ? 'delete' : 'delete'"
              :color="hoveredRow === props.row.id ? 'red' : 'black'"
              @mouseover="hoveredRow = props.row.id"
              @mouseleave="hoveredRow = null"
              @click="deleteKeyword(props.row)"
            />
            <q-tooltip>
            Delete keyword from monitoring
          </q-tooltip>
          </div>
        </q-td>
      </template>
      <template v-slot:body-cell-totalCount="props">
      <q-td :props="props">
        <q-spinner-grid v-if="props.row.loading" /> <!-- Display loading icon if loading is true -->
        <template v-else> <!-- Display the content if loading is false -->
          <template v-if="props.row.isActive">
            {{ props.row.totalCount }}
            <span v-if="props.row.totalCount > 0">
              ({{ Math.round(props.row.percentChange * 100)}}%)
              <q-icon name="trending_up" color="green" v-if="props.row.percentChange > 0" />
              <q-icon name="trending_down" color="red" v-else-if="props.row.percentChange < 0" />
            </span>
          </template>
          <template v-else>
            N/A
          </template>
        </template>
      </q-td>
    </template>
      <template v-slot:body-cell-source="props">
        <q-td :props="props">
            <WebsiteIcon :website="props.row.source" class="website-icon" />
            {{ props.row.source }}
        </q-td>
      </template>
      <template v-slot:body-cell-keyword="props">
        <q-td :props="props" @click="onRowClick(props, 'keyword')">
          {{ doTruncate(props.row.keyword) }}
          <q-tooltip>
            View full keyword
          </q-tooltip>
        <q-dialog v-model="props.row.expandKeyword" max-width="80vw">
        <q-card bordered>
          <q-card-section class="row items-center q-pb-none" style="margin-bottom: 5px;">
          <div class="text-h6" style="margin-right: 200px;">Full Keyword</div>
          <q-space></q-space>
          <q-btn icon="close" flat round dense v-close-popup></q-btn>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section style="max-height: 50vh" class="scroll">
          <div >
            {{ props.row.keyword }}
          </div>
        </q-card-section>
        </q-card>
      </q-dialog>
      </q-td>
      </template>
    </q-table>
  </div>
</div>
</template>
<script>
import WebsiteIcon from '@/components/WebsiteIcon'
import sort from '@/assets/sort.svg'
import moment from 'moment'

export default {
  components: {
    WebsiteIcon
  },
  computed: {
    allSites: {
      get () {
        return Object.values(this.sites).every(site => site.active)
      },
      set (newValue) {
        for (const site in this.sites) {
          this.sites[site].active = newValue
        }
      }
    },
    numActiveSites () {
      return Object.values(this.sites).filter(site => site.active).length
    },
    capacityAvailable () {
      if (this.tab === 'keywords') {
        return (this.totalKeywordsRemaining + this.numNewKeywords) > 0 || this.isAdmin
      } else if (this.tab === 'profiles') {
        return (this.totalProfilesRemaining + this.numNewProfiles) > 0 || this.isAdmin
      } else {
        return (this.totalChannelsRemaining + this.numNewChannels) > 0 || this.isAdmin
      }
    },
    isValid () {
      return this.validateInputFunc()
    },
    isProfileValid () {
      return this.validateInputFunc('profile')
    }
  },
  watch: {
    sites: {
      handler (newSites) {
        const activeCount = Object.values(this.sites).filter(site => site.active).length
        if (this.newKeyword !== '') {
          this.numNewKeywords = this.newKeyword.split('\n').length * activeCount
        }
        if (this.newProfile !== '') {
          this.numNewProfiles = this.newProfile.split('\n').length * activeCount
        }
      },
      deep: true,
    },
    newKeyword (value) {
      const activeCount = Object.values(this.sites).filter(site => site.active).length
      if (value === '') {
        this.numNewKeywords = 0
      } else {
        this.numNewKeywords = value.split('\n').length * activeCount
      }
    },
    newProfile (value) {
      const activeCount = Object.values(this.sites).filter(site => site.active).length
      if (value === '') {
        this.numNewProfiles = 0
      } else {
        this.numNewProfiles = value.split('\n').length * activeCount
      }
    },
    newChannel (value) {
      if (value === '') {
        this.numNewChannels = 0
      } else {
        this.numNewChannels = value.split('\n').length
      }
    },
  },
  data () {
    return {
      abortController: null,
      hoveredRow: null,
      isHovered: false,
      countDropdown: false,
      dropdown: false,
      sortIcon: sort,
      totalKeywordsAvailable: 0,
      totalKeywordsRequested: 0,
      totalKeywordsPercentage: 0,
      totalKeywordsRemaining: 0,
      totalKeywordsLabel: null,
      totalChannelsAvailable: 0,
      totalChannelsRequested: 0,
      totalChannelsRemaining: 0,
      totalChannelsPercentage: 0,
      totalChannelsLabel: null,
      totalProfilesAvailable: 0,
      totalProfilesRequested: 0,
      totalProfilesRemaining: 0,
      totalProfilesPercentage: 0,
      totalProfilesLabel: null,
      isAdmin: false,
      // allSites: true,
      tab: 'keywords',
      channels: [],
      profiles: [],
      keywords: [],
      timeDuration: 'week',
      channel_pagination_last_id: null,
      profile_pagination_last_id: null,
      pagination_last_id: null,
      // expandedIds: [],
      searchTerm: '',
      sortOrder: 1,
      showForm: false,
      newKeyword: '',
      numNewKeywords: 0,
      newProfile: '',
      numNewProfiles: 0,
      newChannel: '',
      numNewChannels: 0,
      // numActiveSites: 0,
      filter: '',
      loading: true,
      inputIsValid: false,
      inputProfileIsValid: false,
      profileOptions: [
        'User',
        'Group',
        'Channel'
      ],
      profileIds: [
        {
          label: 'Username',
          value: 'profile'
        },
        {
          label: 'ID',
          value: 'source_id'
        },
        {
          label: 'URL',
          value: 'source_url'
        },
      ],
      profileId: {
        label: 'Username',
        value: 'profile'
      },
      profileOption: 'User',
      initialPagination: {
        sortBy: 'id',
        descending: true,
        page: 1,
        rowsPerPage: 15,
        rowsNumber: 25
      },
      initialChannelPagination: {
        sortBy: 'id',
        descending: true,
        page: 1,
        rowsPerPage: 15,
        rowsNumber: 25
      },
      initialProfilePagination: {
        sortBy: 'id',
        descending: true,
        page: 1,
        rowsPerPage: 15,
        rowsNumber: 25
      },
      sites: {
        // '4chan': {
        //   name: '4chan',
        //   label: '4chan',
        //   active: false
        // },
        // '8kun': {
        //   name: '8kun',
        //   label: '8kun',
        //   active: false
        // },
        // 'parler': {
        //   name: 'parler',
        //   label: 'Parler',
        //   active: false
        // },
        // 'poal': {
        //   name: 'poal',
        //   label: 'Poal',
        //   active: false
        // },
        communities: {
          name: 'communities',
          label: 'Communities',
          active: true
        },
        // 'telegram': {
        //   name: 'telegram',
        //   label: 'Telegram',
        //   active: false
        // },
        gettr: {
          name: 'gettr',
          label: 'Gettr',
          active: true
        },
        bitchute: {
          name: 'bitchute',
          label: 'Bitchute',
          active: true
        },
        mewe: {
          name: 'mewe',
          label: 'MeWe',
          active: true
        },
        wimkin: {
          name: 'wimkin',
          label: 'Wimkin',
          active: true
        },
        rumble: {
          name: 'rumble',
          label: 'Rumble',
          active: true
        },
        minds: {
          name: 'minds',
          label: 'Minds',
          active: true
        },
        lbry: {
          name: 'lbry',
          label: 'LBRY',
          active: true
        },
        vk: {
          name: 'vk',
          label: 'VK',
          active: true
        },
        truthsocial: {
          name: 'truthsocial',
          label: 'Truth Social',
          active: true
        },
        tiktok: {
          name: 'tiktok',
          label: 'TikTok',
          active: true
        },
        rutube: {
          name: 'rutube',
          label: 'RUTUBE',
          active: true
        },
        gab: {
          name: 'gab',
          label: 'Gab',
          active: true
        },
        ok: {
          name: 'ok',
          label: 'OK',
          active: true
        }
        // bluesky: {
        //   name: 'bluesky',
        //   label: 'Bluesky',
        //   active: false
        // }
      },
      channelColumns: [
        // {
        //   name: 'id',
        //   label: 'ID',
        //   field: 'id',
        //   align: 'left',
        //   sortable: false
        // },
        // {
        //   name: 'isActive',
        //   label: 'Delete',
        //   field: 'isActive',
        //   align: 'center',
        //   sortable: false
        // },
        {
          name: 'toggleStatus',
          label: 'Crawl Active',
          field: 'isActive',
          align: 'center',
          sortable: false
        },
        {
          name: 'last_fetched',
          label: 'Last Fetched',
          field: 'last_fetched',
          align: 'left',
          sortable: false,
          format (value) {
            if (value === null) {
              return ''
            } else {
              return moment(value).format('YYYY-MM-DD')
            }
          }
        },
        {
          name: 'username',
          label: 'Channel',
          field: 'username',
          align: 'left',
          sortable: false
        },
        {
          name: 'channelId',
          label: 'ID',
          field: 'channelId',
          align: 'left',
          sortable: false
        },
        {
          name: 'totalCount',
          label: 'Activity',
          field: 'totalCount',
          align: 'center',
          sortable: false
        },
      ],
      profileColumns: [
        // {
        //   name: 'isActive',
        //   label: 'Delete',
        //   field: 'isActive',
        //   align: 'left',
        //   sortable: false
        // },
        {
          name: 'toggleStatus',
          label: 'Crawl Active',
          field: 'isActive',
          align: 'left',
          sortable: false
        },
        {
          name: 'source',
          label: 'Source',
          field: 'source',
          align: 'left',
          sortable: false,
          format (value) {
            return value.toUpperCase()
          }
        },
        {
          name: 'last_fetched',
          label: 'Last Fetched',
          field: 'last_fetched',
          align: 'left',
          sortable: false,
          format (value) {
            if (value === null) {
              return ''
            } else {
              return moment(value).format('YYYY-MM-DD')
            }
          }
        },
        {
          name: 'id',
          label: 'ID',
          field: 'id',
          align: 'left',
          sortable: false
        },
        {
          name: 'profile',
          label: 'Username',
          field: 'profile',
          align: 'left',
          sortable: false
        },
        {
          name: 'source_id',
          label: 'Source ID',
          field: 'source_id',
          align: 'left',
          sortable: false
        },
        {
          name: 'source_url',
          label: 'Source URL',
          field: 'source_url',
          align: 'left',
          sortable: false
        },
        {
          name: 'datatype',
          label: 'Datatype',
          field: 'datatype',
          align: 'left',
          sortable: false
        },
      ],
      columns: [
        // {
        //   name: 'isActive',
        //   label: 'Delete',
        //   field: 'isActive',
        //   align: 'center',
        //   sortable: false
        // },
        {
          name: 'source',
          label: 'Source',
          field: 'source',
          align: 'left',
          sortable: false,
          format (value) {
            return value.toUpperCase()
          }
        },
        {
          name: 'last_fetched',
          label: 'Last Fetched',
          field: 'last_fetched',
          align: 'left',
          sortable: false,
          format (value) {
            if (value === null) {
              return ''
            } else {
              return moment(value).format('YYYY-MM-DD')
            }
          }
        },
        {
          name: 'id',
          label: 'ID',
          field: 'id',
          align: 'left',
          sortable: false
        },
        {
          name: 'keyword',
          label: 'Keyword',
          field: 'keyword',
          align: 'left',
          sortable: false
        },
        {
          name: 'totalCount',
          label: 'Number of Results',
          field: 'totalCount',
          align: 'center',
          sortable: false
        },
      ]
    }
  },
  methods: {
    validateInputFunc (inputType = 'channel') {
      if (inputType === 'channel') {
        this.inputIsValid = !/(?<!\n) (?!\n)/.test(this.newChannel)
        return this.inputIsValid
        // return !/(?<!\n) (?!\n)/.test(this.newChannel)
      } else {
        this.inputProfileIsValid = !/(?<!\n) (?!\n)/.test(this.newProfile)
        return this.inputProfileIsValid
        // return !/(?<!\n) (?!\n)/.test(this.newProfile)
      }
    },
    async clearSearch (value) {
      this.tab = value
      this.searchTerm = ''
      this.filter = ''
      this.channel_pagination_last_id = null
      this.pagination_last_id = null
      this.profile_pagination_last_id = null
      this.initialPagination.page = 1
      this.initialChannelPagination.page = 1
      this.initialProfilePagination.page = 1
      this.initialChannelPagination.rowsPerPage = 15
      this.initialPagination.rowsPerPage = 15
      this.initialProfilePagination.rowsPerPage = 15
      Object.keys(this.sites).forEach(key => {
        this.sites[key].active = true
      })
      if (this.tab === 'keywords') {
        await this.doServerRequest(true, true)
      } else if (this.tab === 'channels') {
        await this.doChannelRequest(true, true)
      } else if (this.tab === 'profiles') {
        await this.doProfileRequest(true, true)
      }
    },
    resetPaginationId (crawlType = 'keyword') {
      if (crawlType === 'keyword') {
        this.pagination_last_id = null
      }
      if (crawlType === 'telegram') {
        this.channel_pagination_last_id = null
      }
      if (crawlType === 'profile') {
        this.profile_pagination_last_id = null
      }
    },
    updateSiteStatus () {
      this.dropdown = false
      if (this.tab === 'keywords') {
        this.pagination_last_id = null
        this.initialPagination.page = 1
        this.doServerRequest(true, true)
      } else if (this.tab === 'profiles') {
        this.profile_pagination_last_id = null
        this.initialProfilePagination.page = 1
        this.doProfileRequest(true, true)
      }
    },
    openForm () {
      this.showForm = true
    },
    doTruncateId (keyword) {
      if (keyword == null) {
        return ''
      } else if (keyword.length > 10) {
        return keyword.slice(0, 10) + '...'
      } else {
        return keyword
      }
    },
    doTruncate (keyword) {
      if (keyword == null) {
        return ''
      } else if (keyword.length > 20) {
        return keyword.slice(0, 20) + '...'
      } else {
        return keyword
      }
    },
    async updateTimeDurationChannels () {
      this.countDropdown = false
      for (const channel of this.channels) {
        channel.loading = true
      }
      this.channels = await Promise.all(this.channels.map(async channel => {
        if (channel.isActive) {
          const [totalCount, percentChange] = await this.doTimeSeries(channel.username, 'telegram', this.timeDuration)
          return {
            ...channel,
            totalCount: totalCount,
            percentChange: percentChange,
            loading: false
          }
        } else {
          return {
            ...channel,
            totalCount: 0,
            percentChange: 0,
            loading: false
          }
        }
      }))
      // this.countDropdown = false
    },
    async updateTimeDuration () {
      this.countDropdown = false
      for (const keyword of this.keywords) {
        keyword.loading = true
      }
      this.keywords = await Promise.all(this.keywords.map(async keyword => {
        if (keyword.isActive) {
          const [totalCount, percentChange] = await this.doTimeSeries(keyword.keyword, keyword.source, this.timeDuration)
          return {
            ...keyword,
            totalCount: totalCount,
            percentChange: percentChange,
            loading: false
          }
        } else {
          return {
            ...keyword,
            totalCount: 0,
            percentChange: 0,
            loading: false
          }
        }
      }))
      // this.countDropdown = false
    },
    onRowClick (props, column) {
      // props.expand = !props.expand
      if (column === 'id') {
        props.row.expandId = !props.row.expandId
      } else if (column === 'keyword') {
        props.row.expandKeyword = !props.row.expandKeyword
      } else if (column === 'url') {
        props.row.expandUrl = !props.row.expandUrl
      }
    },
    async addFetch (term, site, crawlType = 'keyword') {
      try {
        const token = await this.$auth0.getAccessTokenSilently()
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
        const params = new URLSearchParams({
          source: site
        })
        params.append('crawl_type', crawlType)
        if (crawlType === 'keyword') {
          params.append('keyword', term)
        } else if (crawlType === 'profile') {
          params.append('datatype', this.profileOption.toLowerCase())
          params.append(this.profileId.value, term)
        }
        await fetch(`https://crawl-requests-api-gateway-agrquh75.uc.gateway.dev/crawl_request?${params}`, {
          method: 'POST',
          headers: headers
        })
      } catch (error) {
        console.error(error)
      }
    },
    async addChannelRequest () {
      this.loading = true
      const channels = this.newChannel.split('\n')
      await Promise.all(channels.map(channel => this.addChannel(channel)))
      this.newChannel = ''
      this.loading = false
      this.showForm = false
      await this.doChannelRequest(true, true)
    },
    async addChannel (channel) {
      try {
        const token = await this.$auth0.getAccessTokenSilently()
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
        const params = new URLSearchParams({
          crawl_type: 'telegram',
          channel: channel
        })
        await fetch(`https://crawl-requests-api-gateway-agrquh75.uc.gateway.dev/crawl_request?${params}`, {
          method: 'POST',
          headers: headers
        })
      } catch (error) {
        console.error(error)
      }
    },
    async addProfile () {
      const profiles = this.newProfile.split('\n')
      this.loading = true
      for (const site in this.sites) {
        if (this.sites[site].active) {
          await Promise.all(profiles.map(profile => this.addFetch(profile, this.sites[site].name, 'profile')))
          // await this.addFetch(this.newKeyword, this.sites[site].name, 'profile')
        }
      }
      this.loading = false
      this.newProfile = ''
      this.showForm = false
      await this.clearSearch('profiles')
    },
    async addKeyword () {
      const keywords = this.newKeyword.split('\n')
      this.loading = true
      for (const site in this.sites) {
        if (this.sites[site].active) {
          await Promise.all(keywords.map(keyword => this.addFetch(keyword, this.sites[site].name)))
        }
      }
      this.loading = false
      this.newKeyword = ''
      this.showForm = false
      await this.clearSearch('keywords')
    },
    async deleteProfile (profile) {
      try {
        this.loading = true
        const token = await this.$auth0.getAccessTokenSilently()
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
        const params = new URLSearchParams({
          crawl_type: 'profile',
          crawl_id: profile.id,
          crawl_status: false
        })
        await fetch(`https://crawl-requests-api-gateway-agrquh75.uc.gateway.dev/update_crawl_request?${params}`, {
          method: 'POST',
          headers: headers
        })
        await this.clearSearch('profiles')
        this.loading = false
      } catch (error) {
        console.error(error)
      }
    },
    async deleteChannel (channel) {
      try {
        this.loading = true
        const token = await this.$auth0.getAccessTokenSilently()
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
        const params = new URLSearchParams({
          crawl_type: 'telegram',
          crawl_id: channel.id,
          crawl_status: false
        })
        await fetch(`https://crawl-requests-api-gateway-agrquh75.uc.gateway.dev/update_crawl_request?${params}`, {
          method: 'POST',
          headers: headers
        })
        await this.clearSearch('channels')
        this.loading = false
      } catch (error) {
        console.error(error)
      }
    },
    async updateChannel (channel) {
      try {
        this.loading = true
        const token = await this.$auth0.getAccessTokenSilently()
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
        const params = new URLSearchParams({
          crawl_type: 'telegram',
          crawl_id: channel.id,
          crawl_status: channel.isActive,
          media_status: channel.mediaActive
        })
        await fetch(`https://crawl-requests-api-gateway-agrquh75.uc.gateway.dev/update_crawl_request?${params}`, {
          method: 'POST',
          headers: headers
        })
        this.loading = false
      } catch (error) {
        console.error(error)
      }
    },
    async deleteKeyword (keyword) {
      try {
        this.loading = true
        const token = await this.$auth0.getAccessTokenSilently()
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
        const params = new URLSearchParams({
          crawl_type: 'keyword',
          crawl_id: keyword.id,
          crawl_status: false
        })
        await fetch(`https://crawl-requests-api-gateway-agrquh75.uc.gateway.dev/update_crawl_request?${params}`, {
          method: 'POST',
          headers: headers
        })
        // this.loading = false
        await this.clearSearch('keywords')
      } catch (error) {
        console.error(error)
      }
    },
    async updateProfile (keyword) {
      try {
        this.loading = true
        const token = await this.$auth0.getAccessTokenSilently()
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
        const params = new URLSearchParams({
          crawl_type: 'profile',
          crawl_id: keyword.id,
          crawl_status: keyword.isActive
        })
        await fetch(`https://crawl-requests-api-gateway-agrquh75.uc.gateway.dev/update_crawl_request?${params}`, {
          method: 'POST',
          headers: headers
        })
        this.loading = false
      } catch (error) {
        console.error(error)
      }
    },
    async updateKeyword (keyword) {
      try {
        this.loading = true
        const token = await this.$auth0.getAccessTokenSilently()
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
        const params = new URLSearchParams({
          crawl_type: 'keyword',
          crawl_id: keyword.id,
          crawl_status: keyword.isActive
        })
        await fetch(`https://crawl-requests-api-gateway-agrquh75.uc.gateway.dev/update_crawl_request?${params}`, {
          method: 'POST',
          headers: headers
        })
        this.loading = false
      } catch (error) {
        console.error(error)
      }
    },
    async doProfileRequest (ascending = true, docount = true) {
      try {
        await this.doServerRequest(ascending, docount, 15, 'profile')
      } catch (error) {
        console.error(error)
      }
    },
    async doChannelRequest (ascending = true, docount = true) {
      // console.log('doing channel request')
      try {
        await this.doServerRequest(ascending, docount, 15, 'telegram')
      } catch (error) {
        console.error(error)
      }
    },
    async doTimeSeries (term, site, last) {
      // console.log('doing time series')
      let since, until
      try {
        if (site === 'truthsocial') {
          site = 'truth_social'
        }
        if (site === 'bitchute') {
          site = 'bitchute_video'
        }
        if (site === 'rutube') {
          site = 'rutube_video'
        }
        if (site === 'tiktok') {
          site = 'tiktok_video'
        }
        if (site === 'rumble') {
          site = 'rumble_video'
        }
        if (site === 'lbry') {
          site = 'lbry_video'
        }
        if (site === 'communities') {
          site = 'win'
        }
        if (site === 'truthsocial') {
          site = 'truth_social'
        }
        if (site === 'rumble') {
          site = 'rumble_video'
        }
        if (site === 'rutube') {
          site = 'rutube_video'
        }
        if (site === 'bluesky') {
          return [0, 0]
        }
        if (last === 'day') {
          since = moment().subtract(1, 'days').format('YYYY-MM-DD')
          until = moment().format('YYYY-MM-DD')
        }
        if (last === 'week') {
          since = moment().subtract(7, 'days').format('YYYY-MM-DD')
          until = moment().format('YYYY-MM-DD')
        }
        if (last === 'month') {
          since = moment().subtract(30, 'days').format('YYYY-MM-DD')
          until = moment().format('YYYY-MM-DD')
        }
        if (last === 'year') {
          since = moment().subtract(365, 'days').format('YYYY-MM-DD')
          until = moment().format('YYYY-MM-DD')
        }
        const token = await this.$auth0.getAccessTokenSilently()
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
        const params = new URLSearchParams({
          since: since,
          until: until,
          site: site
        })
        if (site === 'telegram') {
          params.append('term', 'channelusername: ' + term)
          params.append('esquery', 'true')
        }
        if (site !== 'telegram') {
          params.append('term', term)
        }
        const response = await fetch(
          `https://api.openmeasures.io/timeseries?${params}`,
          { headers, signal: this.abortController.signal }
        )
        const responseData = await response.json()
        const createdKey = responseData.created_key
        const aggregations = responseData.aggregations[createdKey].buckets
        let totalDocCount = 0
        let percentChange = 0
        if (aggregations.length > 0) {
          percentChange = Number(((aggregations[aggregations.length - 1].doc_count - aggregations[0].doc_count) / aggregations[0].doc_count).toFixed(2))
          totalDocCount = aggregations.reduce((total, aggregation) => total + aggregation.doc_count, 0)
        }
        // console.log('finished timeseries')
        return [totalDocCount, percentChange]
      } catch (error) {
        console.error(error)
      }
    },
    async doServerRequest (ascending = true, docount = false, nResults = 15, crawlType = 'keyword') {
      // console.log(crawlType)
      if (this.abortController && !this.abortController.signal.aborted) {
        this.abortController.abort()
      }
      this.abortController = new AbortController()
      this.loading = true
      try {
        const token = await this.$auth0.getAccessTokenSilently()
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
        const params = new URLSearchParams({
          crawl_type: crawlType,
          ascending: ascending,
          limit: nResults
        })
        if (crawlType === 'keyword') {
          params.append('sources', Object.keys(this.sites).filter(key => this.sites[key].active).join(','))
        }
        if (crawlType === 'profile') {
          params.append('sources', Object.keys(this.sites).filter(key => this.sites[key].active).join(','))
        }
        if (this.pagination_last_id && crawlType === 'keyword' && this.initialPagination.page > 1) {
          params.append('pagination_id', this.pagination_last_id)
        }
        if (this.profile_pagination_last_id && crawlType === 'profile' && this.initialProfilePagination.page > 1) {
          params.append('pagination_id', this.profile_pagination_last_id)
        }
        if (this.channel_pagination_last_id && crawlType === 'telegram' && this.initialChannelPagination.page > 1) {
          params.append('pagination_id', this.channel_pagination_last_id)
        }

        if (this.searchTerm !== '') {
          // console.log(this.searchTerm)
          params.append('crawl_filter', this.searchTerm)
        }
        const response = await fetch(`https://crawl-requests-api-gateway-agrquh75.uc.gateway.dev/get_crawl_requests?${params}`, { headers, signal: this.abortController.signal })
        const responseData = await response.json()
        // console.log(responseData)
        const data = responseData.data
        try {
          this.isAdmin = responseData.is_admin
          if (this.isAdmin) {
            if (!this.columns.some(column => column.name === 'toggleStatus')) {
              this.columns.unshift({
                name: 'toggleStatus',
                label: 'Crawl Active',
                field: 'isActive',
                align: 'center',
                sortable: false
              })
            }
            if (!this.channelColumns.some(column => column.name === 'toggleMedia')) {
              this.channelColumns.unshift({
                name: 'toggleMedia',
                label: 'Media Active',
                field: 'mediaActive',
                align: 'center',
                sortable: false
              })
            }
          } else {
            if (!this.columns.some(column => column.name === 'isActive')) {
              this.columns.unshift({
                name: 'isActive',
                label: 'Delete',
                field: 'isActive',
                align: 'center',
                sortable: false
              })
            }
            if (!this.channelColumns.some(column => column.name === 'isActive')) {
              this.channelColumns.unshift({
                name: 'isActive',
                label: 'Delete',
                field: 'isActive',
                align: 'center',
                sortable: false
              })
            }
            if (!this.profileColumns.some(column => column.name === 'isActive')) {
              this.profileColumns.unshift({
                name: 'isActive',
                label: 'Delete',
                field: 'isActive',
                align: 'center',
                sortable: false
              })
            }
          }
        } catch (error) {
          console.error(error)
        }
        if (crawlType === 'profile') {
          this.profile_pagination_last_id = responseData.pagination_last_id
          this.totalProfilesAvailable = responseData.subscription.total
          this.totalProfilesRequested = responseData.subscription.used
          this.totalProfilesRemaining = responseData.subscription.remaining
          this.totalProfilesPercentage = (this.totalProfilesRequested / this.totalProfilesAvailable)
          this.totalProfilesLabel = `${this.totalProfilesRequested} / ${this.totalProfilesAvailable}`
          if (docount) {
            this.initialProfilePagination.rowsNumber = responseData.count
          }
          this.profiles = data.map(item => ({
            id: item.id,
            isActive: item.active,
            profile: item.profile,
            source_id: item.source_id,
            datatype: item.datatype,
            source_url: item.source_url,
            source: item.source,
            last_fetched: item.last_fetched
          })).sort((a, b) => a.id > b.id)
        }
        if (crawlType === 'keyword') {
          this.pagination_last_id = responseData.pagination_last_id
          this.totalKeywordsAvailable = responseData.subscription.total
          this.totalKeywordsRequested = responseData.subscription.used
          this.totalKeywordsRemaining = responseData.subscription.remaining
          this.totalKeywordsPercentage = (this.totalKeywordsRequested / this.totalKeywordsAvailable)
          this.totalKeywordsLabel = `${this.totalKeywordsRequested} / ${this.totalKeywordsAvailable}`
          if (docount) {
            this.initialPagination.rowsNumber = responseData.count
          }
          this.keywords = data.map(item => ({
            id: item.id,
            isActive: item.active,
            keyword: item.keyword,
            source: item.source,
            last_fetched: item.last_fetched,
            loading: true
          })).sort((a, b) => a.id > b.id)
          this.loading = false
          this.keywords = await Promise.all(this.keywords.map(async keyword => {
            if (keyword.isActive) {
              const [totalCount, percentChange] = await this.doTimeSeries(keyword.keyword, keyword.source, this.timeDuration)
              return {
                ...keyword,
                totalCount: totalCount,
                percentChange: percentChange,
                loading: false
              }
            } else {
              return {
                ...keyword,
                totalCount: 0,
                percentChange: 0,
                loading: false
              }
            }
          }))
        }
        if (crawlType === 'telegram') {
          this.channel_pagination_last_id = responseData.pagination_last_id
          this.totalChannelsAvailable = responseData.subscription.total
          this.totalChannelsRequested = responseData.subscription.used
          this.totalChannelsRemaining = responseData.subscription.remaining
          this.totalChannelsPercentage = (this.totalChannelsRequested / this.totalChannelsAvailable)
          this.totalChannelsLabel = `${this.totalChannelsRequested} / ${this.totalChannelsAvailable}`
          if (docount) {
            this.initialChannelPagination.rowsNumber = responseData.count
          }
          this.channels = data.map(item => ({
            id: item.primary_id,
            channelId: item.id,
            isActive: item.crawling_status,
            mediaActive: item.media_status,
            username: item.username,
            last_fetched: item.fetched_utc,
            loading: true
          })).sort((a, b) => a.id > b.id)
          this.loading = false
          this.channels = await Promise.all(this.channels.map(async channel => {
            if (channel.isActive) {
              const [totalCount, percentChange] = await this.doTimeSeries(channel.username, 'telegram', this.timeDuration)
              return {
                ...channel,
                totalCount: totalCount,
                percentChange: percentChange,
                loading: false
              }
            } else {
              return {
                ...channel,
                totalCount: 0,
                percentChange: 0,
                loading: false
              }
            }
          }))
        }
        this.loading = false
      } catch (error) {
        console.error(error)
      }
    },
    async onRequest (props, paginationType = this.initialPagination, crawlType = 'keyword') {
      // console.log('onRequest' + crawlType)
      let nResults = props.pagination.rowsPerPage
      let pNum = props.pagination.page
      let ascending = true
      let docount = true
      if (props.filter !== this.searchTerm) {
        this.resetPaginationId(crawlType)
        this.searchTerm = props.filter
        nResults = props.pagination.rowsPerPage
        pNum = 1
        ascending = true
        docount = true
        paginationType.rowsPerPage = nResults
        paginationType.page = pNum
        await this.doServerRequest(ascending, docount, nResults, crawlType)
        return
      }
      if (props.pagination.page === 1) {
        this.resetPaginationId(crawlType)
        nResults = props.pagination.rowsPerPage
        pNum = props.pagination.page
        ascending = true
        docount = false
        paginationType.rowsPerPage = nResults
        paginationType.page = pNum
        await this.doServerRequest(ascending, docount, nResults, crawlType)
        return
      }
      if (props.pagination.page * props.pagination.rowsPerPage >= props.pagination.rowsNumber) {
        this.resetPaginationId(crawlType)
        nResults = props.pagination.rowsNumber % props.pagination.rowsPerPage
        pNum = props.pagination.page
        ascending = false
        docount = false
        paginationType.page = pNum
        await this.doServerRequest(ascending, docount, nResults, crawlType)
        return
      }
      if (props.pagination.rowsPerPage !== paginationType.rowsPerPage) {
        this.resetPaginationId(crawlType)
        pNum = 1
        nResults = props.pagination.rowsPerPage
        ascending = true
        docount = true
        paginationType.rowsPerPage = nResults
        paginationType.page = pNum
        await this.doServerRequest(ascending, docount, nResults, crawlType)
        return
      }
      if (props.pagination.page < paginationType.page) {
        pNum = props.pagination.page
        nResults = props.pagination.rowsPerPage
        ascending = false
        docount = false
        paginationType.rowsPerPage = nResults
        paginationType.page = pNum
        await this.doServerRequest(ascending, docount, nResults, crawlType)
        return
      }
      if (props.pagination.page > paginationType.page) {
        pNum = props.pagination.page
        nResults = props.pagination.rowsPerPage
        ascending = true
        docount = false
        paginationType.rowsPerPage = nResults
        paginationType.page = pNum
        await this.doServerRequest(ascending, docount, nResults, crawlType)
      }
    },
    async onChannelRequest (props) {
      await this.onRequest(props, this.initialChannelPagination, 'telegram')
    },
    async onKeywordRequest (props) {
      await this.onRequest(props, this.initialPagination, 'keyword')
    },
    async onProfileRequest (props) {
      await this.onRequest(props, this.initialProfilePagination, 'profile')
    },
  },
  async mounted () {
    if (this.tab === 'keywords') {
      await this.doServerRequest(true, true)
    }
    if (this.tab === 'profiles') {
      await this.doProfileRequest(true, true)
    }
    if (this.tab === 'channels') {
      await this.doChannelRequest(true, true)
    }
  }
}

</script>
<style scoped lang="scss">
.banner {
  width: 100%;
  background-color: var(--color-blue);  /* Change this to your preferred color */
  color: #212529;  /* Change this to your preferred color */
  text-align: center;
  padding: 10px 0;
  font-family: var(--primary-font);
  font-size: 15px;
}

.icon-container:hover .q-btn.trash-icon {
  visibility: visible;
}

.q-btn.trash-icon {
  visibility: hidden;
}
.add-keyword-button {
  margin-bottom: 20px;
}

$page-padding: 40px;
table {
  width: 100%;
  border-collapse: collapse;
}

button {
  background: none;
  border: none;
  cursor: pointer;

}

.home-header {
  padding: $page-padding var(--spacing-side-padding);
  margin-bottom: 24px;
  width: calc(100vw - 2 * var(--spacing-side-padding));
}

.header {
  display: flex;
  justify-content: space-between;
}
.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.margin-right {
  margin-right: 10px; /* Adjust as needed */
}

.margin-top {
  margin-top: 10px; /* Adjust as needed */
}

</style>