import { createRouter, createWebHistory } from 'vue-router'
import { authGuard } from '@auth0/auth0-vue'
import HomeView from '@/views/HomeView'
import AccountView from '@/views/AccountView'
import TimelineView from '@/views/TimelineView'
import HashtagsView from '@/views/HashtagsView'
import LinksView from '@/views/LinksView'
import ActivityView from '@/views/ActivityView'
import SearchView from '@/views/SearchView'
import CrawlRequests from '@/views/CrawlRequests'

import SettingsTimeline from '@/components/sidebar/SettingsTimeline'
import SettingsHashtags from '@/components/sidebar/SettingsHashtags'
import SettingsLinks from '@/components/sidebar/SettingsLinks'
import SettingsActivity from '@/components/sidebar/SettingsActivity'
import SettingsSearch from '@/components/sidebar/SettingsSearch'

const { HOME, CRAWLREQUESTS, ACCOUNT, TIMELINE, HASHTAGS, LINKS, ACTIVITY, SEARCH } = require('@/constants/tools')

const beforeEnter = authGuard

const routes = [
  {
    path: '/',
    name: HOME,
    components: {
      page: HomeView
    },
    beforeEnter,
  },
  {
    path: '/crawlrequests',
    name: CRAWLREQUESTS,
    components: {
      page: CrawlRequests
    },
    beforeEnter,
  },
  {
    path: '/account',
    name: ACCOUNT,
    components: {
      page: AccountView
    },
    beforeEnter
  },
  {
    path: '/timeline',
    name: TIMELINE,
    components: {
      page: TimelineView,
      settings: SettingsTimeline
    },
    beforeEnter,
  },
  {
    path: '/hashtags',
    name: HASHTAGS,
    components: {
      page: HashtagsView,
      settings: SettingsHashtags
    },
    beforeEnter,
  },
  {
    path: '/links',
    name: LINKS,
    components: {
      page: LinksView,
      settings: SettingsLinks
    },
    beforeEnter,
  },
  {
    path: '/activity',
    name: ACTIVITY,
    components: {
      page: ActivityView,
      settings: SettingsActivity
    },
    beforeEnter,
  },
  {
    path: '/search',
    name: SEARCH,
    components: {
      page: SearchView,
      settings: SettingsSearch
    },
    beforeEnter,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
