<template>
  <span>
    <q-avatar
      v-if='("icon" in icon)'
      :icon="icon.icon"
      :color="activeColor"
      text-color="white"
      :size="`${size}px` || '40px'"
      font-size="26px"
    />
      <div
        class='icon-case'
        :class='{transparent: activeColor === "transparent", "purple-background": makePurple }'
        :style='{ height: `${size}px`, width: `${size}px`, borderRadius: `${size}px` }'
        v-if='("svg" in icon)'>
        <img :src='icon.svg' :style='{ height: `${size*icon.sizeMultiple}px`, width: `${size*icon.sizeMultiple}px` }' />
    </div>
  </span>
</template>

<script>
import AnalysisIcon from '@/assets/analysis.svg'
import SearchIcon from '@/assets/search.svg'
import ApiIcon from '@/assets/api.svg'
import CloseSidebarSvg from '@/assets/sidebar_hide_icon.svg'
import OpenSidebarSvg from '@/assets/sidebar_open.svg'
import SeeMoreTools from '@/assets/SeeMoreTools.svg'
import LinksIcon from '@/assets/links.svg'
import ActivityIcon from '@/assets/activity.svg'
import CrawlIcon from '@/assets/crawl.svg'
import NetworkIcon from '@/assets/network.svg'
import MediaIcon from '@/assets/media.svg'
import AdvancedIcon from '@/assets/Enterprise-Thin.svg'
import { CRAWLREQUESTS } from '../../../constants/tools'

export default {
  props: {
    tool: String,
    makePurple: Boolean,
    size: {
      type: Number,
      default: 40,
    },
    noBackground: Boolean,
  },
  computed: {
    isCurrent () {
      return this.tool === this.$route.name
    },
    activeColor () {
      if (this.noBackground) return 'transparent'
      return (this.isCurrent | this.makePurple) ? 'purple-highlight' : 'faded-gray'
    },
    icon () {
      return {
        trending: { svg: AnalysisIcon, sizeMultiple: 2.5 }, // 'trending_up',
        timeline: { svg: AnalysisIcon, sizeMultiple: 2.5 }, // 'trending_up',
        hashtags: { icon: 'fas fa-hashtag', sizeMultiple: 1.5 },
        links: { svg: LinksIcon, sizeMultiple: 2.5 },
        activity: { svg: ActivityIcon, sizeMultiple: 2.5 },
        search: { svg: SearchIcon, sizeMultiple: 2.5 },
        api: { svg: ApiIcon, sizeMultiple: 2.5 },
        closeSidebar: { svg: CloseSidebarSvg, sizeMultiple: 0.5 },
        openSidebar: { svg: OpenSidebarSvg, sizeMultiple: 0.5 },
        seeMoreTools: { svg: SeeMoreTools, sizeMultiple: 0.5 },
        [CRAWLREQUESTS]: { svg: CrawlIcon, sizeMultiple: 3 },
        network: { svg: NetworkIcon, sizeMultiple: 3 },
        media: { svg: MediaIcon, sizeMultiple: 3 },
        dashboard: { svg: AdvancedIcon, sizeMultiple: 1.5 },
      }[this.tool]
    }
  }
}
</script>

<style scoped lang="scss">
.icon-case {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  // img {
  //   max-width: 37px;
  //   max-height: 37px;
  // }
}

.purple-background {
  background-color: var(--color-white);
}

.transparent {
  // background-color: transparent;
}
</style>
